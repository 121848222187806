@import '../../accets/scss/variables';

.management-contact-title {
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $btnControlFontSize;
  line-height: 24px;
  color: $mainDarkFontColor;
  margin-bottom: 60px;
}

.hello-section  {
  border-radius: 10px;
  background-color: $btnChangeLangBgColor;
  padding: 0 0 0 50px;
  margin-bottom: 40px;

  & .text {
    font-family: $robotoFontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: $newItemTitleFontSize;
    line-height: 19px;
    color: $mainDarkFontColor;
  }
}