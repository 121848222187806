@import '../../accets/scss/variables';

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid $mainLightFontColor;
  color: $mainLightFontColor;
  background-color: $lightColor;
  margin-right: 10px;

  &:hover {
    text-decoration: none;
  }
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color:$lightColor;
  background:$mainLightFontColor;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}