@import '../../../accets/scss/gamification/variables.module.scss';

.body {
  composes: body from '../UserBar.module.scss';
  padding: 60px 0 30px;
  border-bottom: 1px solid #E5E5E5;
  width: 100%;
}

.userInfo {
  composes: userInfo from '../UserBar.module.scss';
}

.avatarImg {
  height: 120px;
  width: 120px;
  border-radius: 4px;
  display: block;
  margin-right: 25px;
  align-self: flex-start;
}

.userName {
  composes: userName from '../UserBar.module.scss';
  margin-bottom: 10px;
  margin-top: 0;
}

.userJob {
  composes: userJob from '../UserBar.module.scss';
  margin-bottom: 12px;
  margin-top: 0;
}

.routeBtnsGroup {
  margin: 0 0 0 auto;
}

.routeBtn {
  composes: routeBtn from '../UserBar.module.scss';
}

.routeBtn_black {
  composes: routeBtn_black from '../UserBar.module.scss';
}

.statsList {
  composes: statList from '../UserBar.module.scss';
}

.stat {
  composes: stat from '../UserBar.module.scss';
}

.balance {
  composes: balance from '../UserBar.module.scss';
}

.kpi {
  composes: kpi from '../UserBar.module.scss';
}

.level {
  composes: level from '../UserBar.module.scss';
}

.statValueTxt {
  composes: statValueTxt from '../UserBar.module.scss';
}

.employeePhoto {
  height: 120px;
  width: 150px;
  border-radius: 4px;
  margin-right: 10px;
}