@import '../../accets/scss/gamification/variables.module.scss';

.shop {
  composes: container from '../../accets/scss/gamification/container.module.scss';
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;

  &Item {
    flex-basis: 49.5%;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  &Link {
    composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
    background-color:#212529;
    padding: 6px 0;
    text-decoration: none;
    color: #ffffff;
    display: block;
    width: 100%;
    transition: all 0.3s;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

.active {
  composes: navLink;
  background-color: $primary-orange;
  color: #000000;

  &:hover {
    color: #000000;
  }
}
