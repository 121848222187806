@import '../../accets/scss/variables';

.nav-project-btn {
  background-color: transparent;
  margin-bottom: 1rem;

  & .project-btn-icon {
    margin-right: 10px;
  }

  & .project-btn-title {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $mainDarkFontColor;
  }

  & .active-navbar-btn {
    color: $mainLightFontColor;
  }

  & .project-btn-arrow-icon {
    top: 0;
    right: 0;
    bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }
}