@import '../../accets/scss/gamification/variables.module.scss';

.body {
  max-width: 456px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 45px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 300;
  transition: all 0.5s;
};

.hide {
  opacity: 0;
  top: 0;
  transform: translate(-100% -50%);
  transition: all 0.5s;
}

.closeBtn {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 20px;
  background: center / contain no-repeat url('../../accets/icons/close-icon.svg');
  border: none;
  outline: none;
  cursor: pointer;
}

.txt {
  composes: semi-bold-typography from '../../accets/scss/gamification/typography.module.scss';
  margin: 0;
  text-align: center;
}

.toTaskBtn {
  composes: btn from '../../accets/scss/gamification/btn.module.scss';
  border: none;
  line-height: 45px;
  width: 181px;
  margin: 20px auto 0;
}

.card {
  composes: item from '../ProductsList/ProductsList.module.scss';
  max-width: 364px;
  margin: 0 auto 20px;
}

.card_sm {
  composes: item_sm from '../ProductsList/ProductsList.module.scss';
  max-width: 106px;
  margin: 0 auto 20px;
}

.credit {
  color: $primary-orange;

  &::after {
    content: ',';
    color: #000;
  }

  &:nth-last-of-type(-n+2)::after {
    content: 'и';
    margin-left: 10px;
  }

  &:last-of-type::after {
    content: '';
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    margin: 0 10px;
  }
}

.flex {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/flex.svg');
  }
}

.kpi {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/kpi.svg');
  }
}

.level {
  &::before {
    background: center / contain no-repeat url('../../accets/icons/level.svg');
  }
}
