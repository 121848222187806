@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

.gamification {
  padding: 0 150px 0 0;
  height: 100vh;
  display: flex;

}

.gamification * {
  font-family: Nunito, Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

.contnet {
  margin-left: 35px;
  width: 100%;
}
