.regular-typography {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.semi-bold-typography {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.bold-typography {
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
}