@import '../../../accets/scss/gamification/variables.module.scss';

.txt {
  composes: txt from '../Modal.module.scss';
}

.credit {
  color: $primary-orange;
  display: inline-block;

  &::after {
    content: ',';
    color: #000;
  }

  &:nth-last-of-type(-n+2)::after {
    content: 'и';
    margin-left: 10px;
  }

  &:last-of-type::after {
    content: '';
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: -4px 10px;
  }
}

.flex {
  &::before {
    background: center / contain no-repeat url('../../../accets/icons/flex.svg');
  }
}

.kpi {
  &::before {
    background: center / contain no-repeat url('../../../accets/icons/kpi.svg');
  }
}

.level {
  &::before {
    background: center / contain no-repeat url('../../../accets/icons/level.svg');
  }
}

.toTaskBtn {
  composes: toTaskBtn from '../Modal.module.scss';
}

.image {
  display: block;
  margin: 0 auto 20px;
}