@import "../../accets/scss/variables";

.edit-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.edit-form-input-container {
  margin-right: 20px;
}

.edit-form-input {
  border: 1px solid $editsFormInputBorderColor;
  border-radius: 10px;
  padding: 11px 10px;
  font-family: $mainFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $loginFormFontSize;
  line-height: 20px;
  color: $mainDarkFontColor;
  min-height: 34px;

  &:focus {
    border: 1px solid $editsFormInputBorderColor;
    outline: none;
  }

  @media (min-width: 992px) {
    padding: 7px 140px 7px 17px;
  }
}

input::-webkit-input-placeholder {
  color: $mainDarkFontColor;
}

.edit-form-btn-container {
  right: 20px;
  top: 5px;
}

.edit-form-label {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-bottom: 0;

  &:last-of-type {
    margin-right: 0;
  }
}

.edit-form-input-file {
  width: 20px;
  height: 20px;
  opacity: 0;
  right: 0;
  cursor: pointer;
}

.edit-form-img {
  object-fit: contain;
  width: 20px;
  height: 20px;
}

.upload-label {
  margin-right: 10px !important;
}

.form-user-photo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 20px;
}