@import '../../accets/scss/variables';

.nav {
  margin-bottom: 5vh !important;

  @media (min-height: 769px) {
    margin-bottom: 5vh !important;
  }
}

.nav-w {
  max-width: 1000px;
}

.nav-link {
  max-width: 166px;
  flex: 1;
  min-height: 129px;
  padding: 20px 0 10px !important;
  background-color: $notificationBtnBgColor;
  border-radius: 10px;
  margin-right: 10px;

  &.activeRoute {
    border: 1px solid $mainLightFontColor;
    background-color: #fff8ed;
  }

  .nav-link-img {
    max-width: 56px;
    max-height: 56px;
    margin-bottom: 25px;
  }

  .nav-link-title {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $navLinkTitleSmallFontSize;
    line-height: 17px;
    color: $mainDarkFontColor;
    max-width: 103px;

    &::first-letter {
      text-transform: uppercase;
    }

    @media (min-width: 1200px) {
      font-size: $navLinkTitleFontSize;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
}

