@import '../../accets/scss/variables';

.sidebarNav-link.btn.btn-link,
a.sidebarNav-link {
  background-color: transparent;
  border: transparent;
  padding: 8px 20px;
  margin-bottom: 15px;

  &:hover,
  &:focus,
  &.active-btn {
    background-color: $btnChangeLangBgColor;
    border: transparent;
    //box-shadow: none;
    border-radius: 7px;
    box-shadow: 1px 1px 1px  $mainLightFontColor, -1px -1px 1px  $mainLightFontColor;
    text-decoration: none;
  }

  .sidebarNav-link-img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .nav-link-title {
    font-family: $mainFontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: $navLinkTitleFontSize;
    line-height: 20px;
    color: $mainDarkFontColor;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
