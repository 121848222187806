@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$mainFontFamily: 'Montserrat', sans-serif;
$additionalFontFamily: 'Quicksand', sans-serif;
$robotoFontFamily: 'Roboto', sans-serif;

$btnChangeLangFontSize: 12px;
$btnControlFontSize: 20px;
$btnControlActiveFontSize: 25px;
$loginFormFontSize: 16px;
$signInBtnFontSize: 16px;
$welcomeTextFontSize: 25px;
$sideBarTitleFontSize: 32px;
$sideBarTitleMobileFontSize: 24px;
$showProjectBtnFontSize: 16px;
$showProjectMobileBtnFontSize: 12px;
$navLinkTitleFontSize: 16px;
$navLinkTitleSmallFontSize: 13px;
$greetingUserTitleFontSize: 18px;
$greetingUserTitleMobileFontSize: 14px;
$greetingUserSubtitleFontSize: 16px;
$projectLinksFontSize: 12px;
$editItemCommentTitleFontSize: 16px;
$commentNameFontSize: 12px;
$financeTitleFontSize: 18px;
$downloadBillBtnFontSize: 16px;
$paymentDataFontSize: 14px;
$paymentSumFontSize: 16px;
$paymentStatusFontSize: 16px;
$editsPageTitleFontSize: 16px;
$notificationDataFontSize: 12px;
$notificationTitleFontSize: 16px;
$notificationTextFontSize: 14px;
$profileUserNameFontSize: 16px;
$projectReportingBtnFontSize: 16px;
$payrollTableFontSize: 14px;
$newItemTitleFontSize: 16px;

$logoLightColor: #fcb231;
$logoDarkColor: #000;
$mainDarkFontColor: #101010;
$btnChangeLangColor: #c2c2c2;
$mainLightFontColor: #ffbb4f;
$btnChangeLangBgColor: rgba(255, 187, 79, 0.1);
$loginPageRightSectionBgColor: rgba(252, 178, 49, 0.08);
$lightColor: #fff;
$btnControlsColor: #828282;
$loginFormLabelColor: #828282;
$sideBarBgColor: #fff9ef;
$sideBarBorderColor: #e0e0e0;
$notificationBtnBgColor: #f3f3f3;
$privateRoomBtnTitleColor: #bdbdbd;
$newEditsBtnBorderColor: #b5b5b5;
$newEditsBtnBgColor: #ddd;
$underReviewBtnBorderColor: #7486ff;
$underReviewBtnBgColor: #d5dcfe;
$discussEditsBtnBorderColor: #ffbb4f;
$discussEditsBtnBgColor: #ffebca;
$acceptedBtnBorderColor: #7fbbe6;
$acceptedBtnBgColor: #d9ebf7;
$doneEditsBtnBorderColor: #9ad898;
$doneEditsBtnBgColor: #e1f3e0;
$rejectEditsBtnBorderColor: #f44545;
$rejectEditsBtnBgColor: #ffd1cb;
$editFormBgColor: #fff9ef;
$editsFormInputBorderColor: #ffbb4f;
$editItemBgColor: #f3f3f3;
$editItemColor: #1f1f1f;
$exampleFileBtnColor: #2a334b;
$contactsEmployeesSeparatorColor: #d3d5db;
$statusExpiredBgColor: #f44545;
$statusExpectedBgColor: #ffbb4f;
$statusPaidBgColor: #9ad898;
$paymentBoxShadow: 0px 0px 20px rgba(151, 151, 151, 0.5);
$paymentMobileDataColor: #979797;
$notificationCircleColor: #ffb61d;
$disabledNotificationColor: #aaadb7;
$notificationBoxShadow: 0px 0px 8px rgba(116, 116, 116, 0.5);
$payrollHeaderBorderColor:  rgba(196, 196, 196, 0.3);
$paymentTableBarBgColor: #EEE;
$newsItemBgColor: #F3F3F3;
//$errorBoxShadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
//  0px 1px 18px 0px rgba(0, 0, 0, 0.12);
